import React from "react";

export function Title({ text, className }) {
  const default_class =
    "font-sans pb-4.5 text-6 leading-8 font-700 leading-32.68px text-center";
  return <h1 className={`${default_class} ${className}`}>{text}</h1>;
}

export function TitleLarge({ text, className }) {
  const default_class =
    "font-sans text-left text-12 font-700 text-tinkerTots-black";
  return <h1 className={`${default_class} ${className}`}>{text}</h1>;
}

export function Subtitle({ text, className }) {
  const default_class =
    "font-sans text-18px leading-8 font-400 leading-24px text-center";
  return (
    <div
      className={`${default_class} ${className}`}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
}

export function BoldParagraph({ text, className }) {
  const default_class =
    "font-sans text-4 leading-6 font-700 leading-24px text-left";
  return <span className={`${default_class} ${className}`}>{text}</span>;
}

export function Paragraph({ text, className }) {
  const default_class =
    "font-sans text-4 leading-6 font-400 leading-24px text-left";
  return (
    <p
      className={`${default_class} ${className}`}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
}
