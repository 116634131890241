import React, { useState, useMemo, useEffect, useRef } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Popup } from "reactjs-popup";

import InfoIcon from "../components/icons/Info";
import { useStore } from "../hooks/useStore";
import { useModalStore } from "../hooks/useModalStore";
import { calculateScores } from "@tinker-tots/shared/score";
import { attributesMap } from "@tinker-tots/shared/constants";
import { Loading } from "../components/Loading";
import { Button } from "../components/Button";

const attributeForTranslation = (s) =>
  `attributes.${s.toLowerCase().replace(/ /g, "-")}.label`;

const Tooltip = ({ tooltip }) => {
  if (!tooltip) return null;
  return (
    <Popup
      trigger={(open) => (
        <div className="ml-2 text-tinkerTots-gray-600">
          <InfoIcon className="font-700" />
        </div>
      )}
      on={["hover", "click"]}
      position="bottom right"
      closeOnDocumentClick
      className="tooltip"
      lockScroll
    >
      <span>{tooltip}</span>
    </Popup>
  );
};

const Range = ({
  value1,
  value2,
  min = -1.0,
  max = 1.0,
  label1 = "",
  label2 = "",
}) => {
  const percentage1 = Math.max(
    0,
    Math.min(100, ((value1 - min) / (max - min)) * 100)
  );
  const percentage2 = Math.max(
    0,
    Math.min(100, ((value2 - min) / (max - min)) * 100)
  );

  return (
    <div className="relative max-sm:max-w-90% max-sm:w-90% sm:w-full h-6 mx-2">
      <div
        style={{
          left: `0%`,
          width: `100%`,
        }}
        className="absolute h-3 bg-tinkerTots-gray-100 rounded-full"
      />
      <div
        style={{ left: "0%", top: "-60%" }}
        className="absolute w-1 h-3 bg-tinkerTots-gray-100 rounded-1"
      />
      <div
        style={{ left: "50%", top: "-60%", transform: "translateX(-50%)" }}
        className="absolute w-1 h-3 bg-tinkerTots-gray-100 rounded-1"
      />
      <div
        style={{ right: "0%", top: "-60%" }}
        className="absolute w-1 h-3 bg-tinkerTots-gray-100 rounded-1"
      />
      <div
        style={{
          left: `${percentage1}%`,
          bottom: "25%",
          transform: "translateX(-50%)",
        }}
        className="absolute w-6 h-6 bg-tinkerTots-primary-600 rounded-full border-2 border-tinkerTots-white z-2"
      >
        {label1 && (
          <span
            style={{
              bottom: "120%",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            className="absolute bg-tinkerTots-black text-tinkerTots-white text-3 rounded-2 px-2 py-1 text-center font-700"
          >
            {label1}
            <span
              style={{
                top: "80%",
                left: "50%",
                transform: "translateX(-50%) rotate(45deg)",
              }}
              className="absolute w-2 h-2 bg-tinkerTots-black"
            ></span>
          </span>
        )}
      </div>
      <div
        style={{
          left: `${percentage2}%`,
          bottom: "25%",
          transform: "translateX(-50%)",
        }}
        className="absolute w-6 h-6 bg-tinkerTots-system-warning rounded-full border-2 border-tinkerTots-white z-1"
      >
        {label2 && (
          <span
            style={{
              bottom: "-140%",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            className="absolute  bg-tinkerTots-black text-tinkerTots-white text-3 rounded-2 px-2 py-1 text-center font-700"
          >
            {label2}
            <span
              style={{
                bottom: "80%",
                left: "50%",
                transform: "translateX(-50%) rotate(45deg)",
              }}
              className="absolute w-2 h-2 bg-tinkerTots-black text-tinkerTots-white"
            ></span>
          </span>
        )}
      </div>
    </div>
  );
};

const RangeEnd = ({ image, label }) => (
  <div className="max-sm:hidden flex flex-col items-center">
    <img className="h-80px min-w-80px" src={image} alt={label} />
    <h2 className="max-w-96px break-words hyphens-auto">{label}</h2>
  </div>
);

const MobileRangeEnd = ({ image, label, right = true }) => (
  <div
    className={`sm:hidden flex items-center bottom-1 ${
      right ? "right-0" : "left-0"
    } absolute`}
  >
    {right && (
      <h2 className="max-w-96px break-words hyphens-auto text-3 leading-4">
        {label}
      </h2>
    )}
    <img className="h-40px min-w-40px" src={image} alt={label} />
    {!right && (
      <h2 className="max-w-96px break-words hyphens-auto text-3 leading-4">
        {label}
      </h2>
    )}
  </div>
);

const RangeEndCompound = ({
  image1,
  image2,
  chanceLevelImage1,
  chanceLevelImage2,
  imageLabel1,
  imageLabel2,
  chanceLevelLabel1,
  chanceLevelLabel2,
}) => (
  <div className="max-sm:hidden flex flex-col items-center">
    <div className="flex justify-center items-center">
      <img className="h-80px min-w-80px" src={image1} alt={imageLabel1} />
      <img
        className="h-80px min-w-40px"
        src={chanceLevelImage1}
        alt={chanceLevelLabel1}
      />
      <div className="capitalize">{chanceLevelLabel1}</div>
    </div>
    <div className="h-8 w-full" />
    <div className="flex justify-center items-center">
      <img className="h-80px min-w-80px" src={image2} alt={imageLabel2} />
      <img
        className="h-80px min-w-40px"
        src={chanceLevelImage2}
        alt={chanceLevelLabel2}
      />
      <div className="capitalize">{chanceLevelLabel2}</div>
    </div>
  </div>
);

const MobileRangeEndCompound = ({
  image1,
  image2,
  chanceLevelLabel1,
  chanceLevelLabel2,
  right = true,
}) => {
  const Row = ({ image, label }) => (
    <div className="flex">
      {right && (
        <h2 className="max-w-56px text-3 leading-4 flex items-center capitalize">
          {label}
        </h2>
      )}
      <img className="h-36px min-w-36px" src={image} alt={label} />
      {!right && (
        <h2 className="max-w-56px text-3 leading-4 flex items-center capitalize">
          {label}
        </h2>
      )}
    </div>
  );
  return (
    <div className={`sm:hidden flex flex-col items-center`}>
      <Row image={image1} label={chanceLevelLabel1} />
      <div className="h-1 w-full" />
      <Row image={image2} label={chanceLevelLabel2} />
    </div>
  );
};

const Result = ({
  title = "",
  subtitle,
  scores: { user, others },
  scoreLabel1 = "",
  scoreLabel2 = "",
  labels: { left = "avoided", right = "sought" },
  images,
  tooltip,
}) => (
  <div className="flex flex-col w-full">
    {title && <div className="text-6 font-700 pb-2 mx-auto">{title}</div>}
    <div className="flex justify-center items-center mx-auto font-700 py-2">
      {subtitle}
      <Tooltip tooltip={tooltip} />
    </div>
    <div className="flex justify-center items-center h-24 sm:h-31 relative">
      <RangeEnd image={images.left} label={left} />
      <MobileRangeEnd image={images.left} label={left} right={false} />
      <Range
        value1={user}
        value2={others}
        label1={scoreLabel1}
        label2={scoreLabel2}
      />
      <MobileRangeEnd image={images.right} label={right} />
      <RangeEnd image={images.right} label={right} />
    </div>
  </div>
);

const ResultCompound = ({
  title = "",
  subtitle,
  scores: { user, others },
  scoreLabel1 = "",
  scoreLabel2 = "",
  labels,
  images,
  tooltip,
}) => (
  <div className="flex flex-col w-full">
    {title && (
      <div className="text-6 font-700 pb-2 mx-auto max-sm:text-center">
        {title}
      </div>
    )}
    <div className="flex justify-center items-center mx-auto font-700 py-2">
      {subtitle}
      <Tooltip tooltip={tooltip} />
    </div>
    <div className="flex max-sm:flex-col sm:flex-row justify-center items-center h-40 sm:h-44 relative">
      <RangeEndCompound
        image1={images.left1}
        image2={images.left2}
        chanceLevelImage1={images.chanceLevelImages.left1}
        chanceLevelImage2={images.chanceLevelImages.left2}
        chanceLevelLabel1={labels.left1}
        chanceLevelLabel2={labels.left2}
      />
      <div className="flex justify-center sm:px-4 w-full">
        <Range
          value1={user}
          value2={others}
          label1={scoreLabel1}
          label2={scoreLabel2}
          min={0}
          max={1}
        />
      </div>
      <RangeEndCompound
        image1={images.right1}
        image2={images.right2}
        chanceLevelImage1={images.chanceLevelImages.right1}
        chanceLevelImage2={images.chanceLevelImages.right2}
        chanceLevelLabel1={labels.right1}
        chanceLevelLabel2={labels.right2}
      />
      <div className="sm:hidden flex justify-between items-center w-full">
        <MobileRangeEndCompound
          image1={images.left1}
          image2={images.left2}
          chanceLevelLabel1={labels.left1}
          chanceLevelLabel2={labels.left2}
          right={false}
        />
        <MobileRangeEndCompound
          image1={images.right1}
          image2={images.right2}
          chanceLevelLabel1={labels.right1}
          chanceLevelLabel2={labels.right2}
        />
      </div>
    </div>
  </div>
);

export function ResultsSummary() {
  const { t } = useTranslation("results");
  const { t: tCard } = useTranslation("card");
  const pairs = useStore((state) => state.pairs);
  const comparisons = useStore((state) => state.comparisons);
  const resetSession = useStore((state) => state.resetSession);
  const surveyCompleted = useStore((state) => state.surveyCompleted);
  const setResults = useStore((state) => state.setResults);
  const resultsUploadedRef = useRef(false);
  const openSocialShareModal = useModalStore(
    (state) => state.openSocialShareModal
  );
  const navigate = useNavigate();
  const {
    data: { attribute2Scores, attribute1Scores, genderScore, dilemmaScores },
  } = useLoaderData();
  const [reseting, setReseting] = useState(false);
  // TODO: remove after testing

  const restartPlay = async () => {
    setReseting(true);
    await resetSession();
    navigate("/play");
  };

  const share = () => {
    openSocialShareModal();
  };

  const {
    genderPreference,
    attribute1AboveScore,
    attribute1BelowScore,
    attribute2AboveScore,
    attribute2BelowScore,
    dilemmaAScore,
    dilemmaBScore,
    valid,
  } = useMemo(() => {
    const valid =
      pairs?.length > 0 &&
      pairs.filter((pair) => pair[0].chosen || pair[1].chosen).length ===
        pairs.length &&
      comparisons.length > 0 &&
      comparisons.filter((comparison) => comparison.chosen).length > 0;
    if (!valid) return { valid };
    return { ...calculateScores({ pairs, comparisons }), valid };
  }, [pairs, comparisons]);

  useEffect(() => {
    if (valid && !resultsUploadedRef.current) {
      setResults({
        genderPreference,
        attribute1AboveAverageScore: attribute2AboveScore,
        attribute1BelowAverageScore: attribute2BelowScore,
        attribute2AboveAverageScore: attribute1AboveScore,
        attribute2BelowAverageScore: attribute1BelowScore,
        dilemma1Score: dilemmaAScore,
        dilemma2Score: dilemmaBScore,
      });
      resultsUploadedRef.current = true;
    }
  }, [
    setResults,
    valid,
    genderPreference,
    attribute2AboveScore,
    attribute2BelowScore,
    attribute1AboveScore,
    attribute1BelowScore,
    dilemmaAScore,
    dilemmaBScore,
  ]);

  if (!valid) return <Loading />;
  return (
    <div>
      <div className="flex flex-col p-4 sm:p-16">
        <div className="text-8 font-600 py-4">{t("title")}</div>
        <div>{t("subtitle")}</div>
        <div className="h-15 w-full" />
        <Result
          title={t("sex-preference")}
          subtitle={t("overall")}
          scores={{ user: genderPreference, others: genderScore }}
          scoreLabel1={t("you")}
          scoreLabel2={t("others")}
          labels={{
            left: tCard("genders.female"),
            right: tCard("genders.male"),
          }}
          images={{
            left: "images/genders/female.svg",
            right: "images/genders/male.svg",
          }}
          tooltip={t("tooltip-gender")}
        />
        <div className="h-10 w-full" />
        <Result
          title={tCard(attributeForTranslation(pairs[0][0].attribute2))}
          subtitle={`${t("when-the-chances-were")} ${t("above")} ${t(
            "average"
          )}`}
          scores={{
            user: attribute2AboveScore,
            others: attribute2Scores.aboveAverageScore,
          }}
          scoreLabel1={t("you")}
          scoreLabel2={t("others")}
          labels={{ left: t("avoided"), right: t("sought") }}
          images={{ left: "images/avoided.svg", right: "images/sought.svg" }}
          tooltip={`${t("tooltip-above", {
            attribute: tCard(attributeForTranslation(pairs[0][0].attribute2)),
          })}`}
        />
        <Result
          subtitle={`${t("when-the-chances-were")} ${t("below")} ${t(
            "average"
          )}`}
          scores={{
            user: attribute2BelowScore,
            others: attribute2Scores.belowAverageScore,
          }}
          scoreLabel1={t("you")}
          scoreLabel2={t("others")}
          labels={{ left: t("avoided"), right: t("sought") }}
          images={{ left: "images/avoided.svg", right: "images/sought.svg" }}
          tooltip={`${t("tooltip-below", {
            attribute: tCard(attributeForTranslation(pairs[0][0].attribute2)),
          })}`}
        />
        <div className="h-10 w-full" />
        <Result
          title={tCard(attributeForTranslation(pairs[0][0].attribute1))}
          subtitle={`${t("when-the-chances-were")} ${t("above")} ${t(
            "average"
          )}`}
          scores={{
            user: attribute1AboveScore,
            others: attribute1Scores.aboveAverageScore,
          }}
          scoreLabel1={t("you")}
          scoreLabel2={t("others")}
          labels={{ left: t("avoided"), right: t("sought") }}
          images={{ left: "images/avoided.svg", right: "images/sought.svg" }}
          tooltip={`${t("tooltip-above", {
            attribute: tCard(attributeForTranslation(pairs[0][0].attribute1)),
          })}`}
        />
        <Result
          subtitle={`${t("when-the-chances-were")} ${t("below")} ${t(
            "average"
          )}`}
          scores={{
            user: attribute1BelowScore,
            others: attribute1Scores.belowAverageScore,
          }}
          scoreLabel1={t("you")}
          scoreLabel2={t("others")}
          labels={{ left: t("avoided"), right: t("sought") }}
          images={{ left: "images/avoided.svg", right: "images/sought.svg" }}
          tooltip={`${t("tooltip-below", {
            attribute: tCard(attributeForTranslation(pairs[0][0].attribute1)),
          })}`}
        />
        <div className="h-10 w-full" />
        <ResultCompound
          title={`${tCard(attributeForTranslation(pairs[0][1].attribute2))} ${t(
            "vs"
          )} ${tCard(attributeForTranslation(pairs[0][1].attribute1))}`}
          subtitle={t("compound-label-1")}
          scores={{ user: dilemmaAScore, others: dilemmaScores.matchedScore }}
          scoreLabel1={t("you")}
          scoreLabel2={t("others")}
          labels={{
            left1: `${t("above")} ${t("average")} ${t("chances")}`,
            left2: `${t("above")} ${t("average")} ${t("chances")}`,
            right1: `${t("below")} ${t("average")} ${t("chances")}`,
            right2: `${t("below")} ${t("average")} ${t("chances")}`,
          }}
          images={{
            left1: attributesMap[pairs[0][1].attribute2].image,
            left2: attributesMap[pairs[0][1].attribute1].image,
            right1: attributesMap[pairs[0][1].attribute2].image,
            right2: attributesMap[pairs[0][1].attribute1].image,
            chanceLevelImages: {
              left1: "images/chanceLevels/significantly-above-average.svg",
              left2: "images/chanceLevels/significantly-above-average.svg",
              right1: "images/chanceLevels/significantly-below-average.svg",
              right2: "images/chanceLevels/significantly-below-average.svg",
            },
          }}
          tooltip={t("tooltip-compound-same", {
            attribute1: tCard(attributeForTranslation(pairs[0][1].attribute2)),
            attribute2: tCard(attributeForTranslation(pairs[0][1].attribute1)),
          })}
        />
        <div className="max-sm:hidden h-12 w-full" />
        <ResultCompound
          subtitle={t("compound-label-2")}
          scores={{ user: dilemmaBScore, others: dilemmaScores.mixedScore }}
          scoreLabel1={t("you")}
          scoreLabel2={t("others")}
          labels={{
            left1: `${t("above")} ${t("average")} ${t("chances")}`,
            left2: `${t("below")} ${t("average")} ${t("chances")}`,
            right1: `${t("below")} ${t("average")} ${t("chances")}`,
            right2: `${t("above")} ${t("average")} ${t("chances")}`,
          }}
          images={{
            left1: attributesMap[pairs[0][1].attribute2].image,
            left2: attributesMap[pairs[0][1].attribute1].image,
            right1: attributesMap[pairs[0][1].attribute2].image,
            right2: attributesMap[pairs[0][1].attribute1].image,
            chanceLevelImages: {
              left1: "images/chanceLevels/significantly-above-average.svg",
              left2: "images/chanceLevels/significantly-below-average.svg",
              right1: "images/chanceLevels/significantly-below-average.svg",
              right2: "images/chanceLevels/significantly-above-average.svg",
            },
          }}
          tooltip={t("tooltip-compound-mixed", {
            attribute1: tCard(attributeForTranslation(pairs[0][1].attribute2)),
            attribute2: tCard(attributeForTranslation(pairs[0][1].attribute1)),
          })}
        />
      </div>
      {!surveyCompleted && (
        <div className="flex flex-col justify-center items-center h-118px w-full">
          <div className="leading-6 w-full text-center pb-4 font-600">
            {t("survey-prompt")}
          </div>
          <Button
            className="mr-2"
            onClick={() => navigate("/survey")}
            text={t("survey-button-label")}
            disabled={reseting}
          />
        </div>
      )}
      <div className="flex justify-center items-center h-118px w-full mb-15">
        <Button
          className="max-sm:h-58px mr-2 py-4 px-6 text-4.5 leading-6"
          secondary
          onClick={restartPlay}
          text={t("play-again")}
          disabled={reseting}
        />
        <Button
          className="max-sm:h-58px ml-2 py-4 px-6 text-4.5 leading-6"
          onClick={share}
          text={t("share-results")}
          disabled={reseting}
        />
      </div>
    </div>
  );
}
