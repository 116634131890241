import { ChanceLevels, Genders } from "./constants";

export const belowAverageSet = new Set([
  ChanceLevels.SLIGHTLY_BELOW_AVERAGE,
  ChanceLevels.MODERATELY_BELOW_AVERAGE, 
  ChanceLevels.SIGNIFICANTLY_BELOW_AVERAGE, 
]);

export const aboveAverageSet = new Set([
  ChanceLevels.SLIGHTLY_ABOVE_AVERAGE,
  ChanceLevels.MODERATELY_ABOVE_AVERAGE, 
  ChanceLevels.SIGNIFICANTLY_ABOVE_AVERAGE, 
]);

const choseBelowAverageVsAboveAverage = (chosen, other) => (
  (belowAverageSet.has(chosen.attribute2ChanceLevel) && belowAverageSet.has(chosen.attribute1ChanceLevel)) &&
  (aboveAverageSet.has(other.attribute2ChanceLevel) && aboveAverageSet.has(other.attribute1ChanceLevel))
);

const choseAboveAverageVsBelowAverage = (chosen, other) => (
  (aboveAverageSet.has(chosen.attribute2ChanceLevel) && aboveAverageSet.has(chosen.attribute1ChanceLevel)) &&
  (belowAverageSet.has(other.attribute2ChanceLevel) && belowAverageSet.has(other.attribute1ChanceLevel))
);

const choseMixedWithChosenConditionBelowAverage = (chosen, other) => (
  (belowAverageSet.has(chosen.attribute2ChanceLevel) && aboveAverageSet.has(chosen.attribute1ChanceLevel)) &&
  (aboveAverageSet.has(other.attribute2ChanceLevel) && belowAverageSet.has(other.attribute1ChanceLevel))
);

const choseMixedWithChosenConditionAboveAverage = (chosen, other) => (
  (aboveAverageSet.has(chosen.attribute2ChanceLevel) && belowAverageSet.has(chosen.attribute1ChanceLevel)) &&
  (belowAverageSet.has(other.attribute2ChanceLevel) && aboveAverageSet.has(other.attribute1ChanceLevel))
);

const findChosen = (pair) => ({
    chosen: pair.filter((card) => card.chosen)?.[0], 
    other: pair.filter((card) => !card.chosen)?.[0]
});

const countChanceLevel = (chosenChanceLevel, otherChanceLevel, countObj) => {
  if (aboveAverageSet.has(chosenChanceLevel)) {
    countObj.choseAboveAverageCount += 1;
    countObj.aboveAverageCount += 1;
  } else if (aboveAverageSet.has(otherChanceLevel)) {
    countObj.aboveAverageCount += 1;
  }

  if (belowAverageSet.has(chosenChanceLevel)) {
    countObj.choseBelowAverageCount += 1;
    countObj.belowAverageCount += 1;
  } else if (belowAverageSet.has(otherChanceLevel)) {
    countObj.belowAverageCount += 1;
  }

  if (chosenChanceLevel === ChanceLevels.AVERAGE) {
    countObj.choseAverageCount += 1;
    countObj.averageCount += 1;
  } else if (otherChanceLevel === ChanceLevels.AVERAGE) {
    countObj.averageCount += 1;
  }
}

export const decisionSummary = (phase1) => {
  let counts = {
    maleCount: 0,
    genderDilemmaCount: 0,
    choseMaleDilemmaCount: 0,
    dilemma1Count: 0,
    dilemma2Count: 0,
    choseSide2Dilemma1Count: 0,
    choseSide2Dilemma2Count: 0,
    attribute1Counts: {
      aboveAverageCount: 0,
      belowAverageCount: 0,
      averageCount: 0,
      choseAboveAverageCount: 0,
      choseBelowAverageCount: 0,
      choseAverageCount: 0,
    },
    attribute2Counts: {
      aboveAverageCount: 0,
      belowAverageCount: 0,
      averageCount: 0,
      choseAboveAverageCount: 0,
      choseBelowAverageCount: 0,
      choseAverageCount: 0,
    }
  };

  for (let i = 0; i < phase1.length; i += 1) {
    const {chosen, other } = findChosen(phase1[i]);
    if (! chosen) {
      continue;
    }

    countChanceLevel(chosen.attribute1ChanceLevel, other.attribute1ChanceLevel, counts.attribute1Counts);
    countChanceLevel(chosen.attribute2ChanceLevel, other.attribute2ChanceLevel, counts.attribute2Counts);
    if (chosen.gender === Genders.MALE ) {
      counts.maleCount += 1;
      if (other.gender === Genders.FEMALE ) {
        counts.genderDilemmaCount += 1;
        counts.choseMaleDilemmaCount += 1;
      }
    } else if (other.gender ===  Genders.MALE ) {
      counts.maleCount += 1;
      counts.genderDilemmaCount += 1;
    }
    if (choseBelowAverageVsAboveAverage(chosen, other)) {
      counts.dilemma1Count += 1;
      counts.choseSide2Dilemma1Count += 1;
    } else if (choseAboveAverageVsBelowAverage(chosen, other)) {
      counts.dilemma1Count += 1;
    } else if (choseMixedWithChosenConditionBelowAverage(chosen, other)) {
      counts.dilemma2Count += 1;
      counts.choseSide2Dilemma2Count += 1;
    } else if (choseMixedWithChosenConditionAboveAverage(chosen, other)) {
      counts.dilemma2Count += 1;
    }
  }
  return counts;
}

// Function to calculate sex preference
export function CalculateSexPreference({choseMaleDilemmaCount, genderDilemmaCount, phase2ChoseMaleCount, phase2MaleCount}) {
  let scorePhase1 = 0.5;
  if (genderDilemmaCount !== 0) {
    scorePhase1 = choseMaleDilemmaCount / genderDilemmaCount;
  }

  let scorePhase2 = 0.5;
  if (phase2MaleCount !== 0){
    scorePhase2 = phase2ChoseMaleCount / phase2MaleCount;
  }

  return (scorePhase1 + scorePhase2) / 2;
}

export function calculateScore({choseCount, appearedCount, choseAverage, averageAppeared}) {
 let choseScore = 0.5;
  if (appearedCount !== 0) {
    choseScore = choseCount / appearedCount;
  }
  
  let averageScore = 0.5;
  if (averageAppeared !== 0) {
    averageScore = choseAverage / averageAppeared;
  }

  return choseScore - averageScore;
}

export function calculateScores({ pairs, comparisons }) {
  const summary = decisionSummary(pairs);

  const phase2MaleOptions = comparisons.filter(c => c.gender === Genders.MALE )
  const phase2MaleCount = phase2MaleOptions.length
  const phase2ChoseMaleCount = phase2MaleOptions.filter(c => c.chosen).length

  const genderPreference = CalculateSexPreference({
    choseMaleDilemmaCount: summary.choseMaleDilemmaCount,
    genderDilemmaCount: summary.genderDilemmaCount,
    phase2MaleCount,
    phase2ChoseMaleCount,
  })

  const { attribute1Counts, attribute2Counts } = summary
  const attribute1AboveScore = calculateScore({
    choseCount: attribute1Counts.choseAboveAverageCount,
    appearedCount: attribute1Counts.aboveAverageCount,
    choseAverage: attribute1Counts.choseAverageCount,
    averageAppeared: attribute1Counts.averageCount,
  })

  const attribute1BelowScore = calculateScore({
    choseCount: attribute1Counts.choseBelowAverageCount,
    appearedCount: attribute1Counts.belowAverageCount,
    choseAverage: attribute1Counts.choseAverageCount,
    averageAppeared: attribute1Counts.averageCount,
  })

  const attribute2AboveScore = calculateScore({
    choseCount: attribute2Counts.choseAboveAverageCount,
    appearedCount: attribute2Counts.aboveAverageCount,
    choseAverage: attribute2Counts.choseAverageCount,
    averageAppeared: attribute2Counts.averageCount,
  })

  const attribute2BelowScore = calculateScore({
    choseCount: attribute2Counts.choseBelowAverageCount,
    appearedCount: attribute2Counts.belowAverageCount,
    choseAverage: attribute2Counts.choseAverageCount,
    averageAppeared: attribute2Counts.averageCount,
  })
  const { dilemma1Count, choseSide2Dilemma1Count, dilemma2Count, choseSide2Dilemma2Count } = summary;
  const dilemmaAScore = dilemma1Count > 0 ? choseSide2Dilemma1Count / dilemma1Count : 0.5;
  const dilemmaBScore = dilemma2Count > 0 ? choseSide2Dilemma2Count / dilemma2Count : 0.5;

  return {
    genderPreference,
    attribute1AboveScore,
    attribute1BelowScore,
    attribute2AboveScore,
    attribute2BelowScore,
    dilemmaAScore,
    dilemmaBScore,
  }
}