import React from "react";
import { useTranslation } from "react-i18next";

import LinkIcon from "../components/icons/Link";
import { Title, Subtitle } from "../components/Text";
import { CollaboratorBox } from "../components/CollaboratorBox.jsx";
import { SocialShareLinks } from "./SocialShare.jsx";

// TODO: replace with real links and remove this fn
function build_links(count) {
  return Array(count)
    .fill(0)
    .map((x, i) => ({
      href: `https://www.example.com`,
      text: `Link`,
    }));
}

export function About() {
  const { t } = useTranslation("about");
  const { t: t_landing } = useTranslation("landing");
  return (
    <div className="flex flex-col py-4 sm:px-16 items-center justify-center max-sm:w-343px max-w-screen-xxl mx-auto sm:w-870px pb-40">
      <div className="text-center text-8 sm:text-12 font-700 text-tinkerTots-black pb-4">
        {t("title")}
      </div>
      <Subtitle className="text-left" text={t("subtitle")} />
      <div className="py-4">
        <div className="text-center pb-2 font-700 text-5">
          {t("social-heading")}
        </div>
        <div className="text-center pb-2 font-400 text-tinkerTots-gray-600">
          {t("social-subheading")}
        </div>
        <SocialShareLinks />
      </div>
      <Section
        title={t("concept.title")}
        links={[
          { href: t("concept.link-1.href"), text: t("concept.link-1.text") },
          { href: t("concept.link-2.href"), text: t("concept.link-2.text") },
        ]}
      />
      <Section
        title={t("principal-investigators.title")}
        links={[
          {
            href: t("principal-investigators.link-1.href"),
            text: t("principal-investigators.link-1.text"),
          },
          {
            href: t("principal-investigators.link-2.href"),
            text: t("principal-investigators.link-2.text"),
          },
          {
            href: t("principal-investigators.link-3.href"),
            text: t("principal-investigators.link-3.text"),
          },
        ]}
      />
      <Section
        title={t("lead-researcher.title")}
        links={[
          {
            href: t("lead-researcher.link-1.href"),
            text: t("lead-researcher.link-1.text"),
          },
        ]}
      />
      <Section
        title={t("team.title")}
        links={[
          { href: t("team.link-1.href"), text: t("team.link-1.text") },
          { href: t("team.link-2.href"), text: t("team.link-2.text") },
          { href: t("team.link-3.href"), text: t("team.link-3.text") },
        ]}
      />
      <SectionBreak text={t("acknowledgments")} />
      <div
        className="w-full"
        dangerouslySetInnerHTML={{ __html: t("acknowledgments-text") }}
      />
      <SectionBreak text={t("a-collaboration-between")} />
      <div className="flex max-sm:flex-col justify-center items-center">
        <img
          className="h-30 w-auto px-10"
          src="images/collaborators/Oxford.png"
          alt={t_landing("collaborators-alt-1")}
        />
        <img
          className="h-30 w-auto px-10"
          src="images/collaborators/NUS.png"
          alt={t_landing("collaborators-alt-2")}
        />
        <img
          className="h-30 w-auto px-10"
          src="images/collaborators/Exeter.png"
          alt={t_landing("collaborators-alt-3")}
        />
        <img
          className="h-30 w-auto px-10"
          src="images/collaborators/Antitheses.png"
          alt={t_landing("collaborators-alt-4")}
        />
        <img
          className="h-30 w-auto px-10"
          src="images/collaborators/Wellcome-funded by.png"
          alt={t_landing("collaborators-alt-5")}
        />
      </div>
    </div>
  );
}

const SectionBreak = ({ text }) => (
  <div className="w-full flex items-center py-4">
    <hr className="flex-grow border-gray-300 min-w-10" />
    <span className="text-tinkerTots-black text-6 font-700 px-4 max-w-340px text-center">
      {text}
    </span>
    <hr className="flex-grow border-gray-300 min-w-10" />
  </div>
);

const Section = ({ title, links }) => (
  <div className="w-full">
    <SectionBreak text={title} />
    <LinkList links={links} />
  </div>
);

const LinkList = ({ links }) => (
  <div className="flex max-sm:flex-col sm:flex-row items-center justify-center">
    {links.map((link, ii) => (
      <div
        key={ii}
        className="flex sm:justify-center align-middle text-tinkerTots-primary-main text-18px w-284px h-8"
      >
        <a href={link.href} target="_blank" rel="noreferrer">
          <div className="flex items-center h-8 pb-2">
            <LinkIcon className="mr-2  text-tinkerTots-primary-main" />
            {link.text}
          </div>
        </a>
      </div>
    ))}
  </div>
);

const WritingListItem = ({ text, link }) => (
  <li className="text-left">
    <span className="text-18px leading-8">{text}</span>
    <br />

    <a
      className="text-tinkerTots-primary-main"
      href={link.href}
      target="_blank"
      rel="noreferrer"
    >
      <div className="flex items-center h-8 pb-2">
        <LinkIcon className="mr-2  text-tinkerTots-primary-main" />[{link.text}]
      </div>
    </a>
  </li>
);
